<template>
  <div class="col-12 mb-3" :class="{'col-md-6': user, 'col-md-5': !user}">
    <CardPlain>
      <div class="row mx-0 justify-content-center text-center text-md-start">
        <div class="col-auto col-md-12">
          <div class="form-check">
            <div class="row m-0 align-items-center">
              <div class="col-auto p-0">
                <input class="form-check-input" type="checkbox" value="true" id="giftaid" v-model="giftAidValue">
              </div>
              <div class="col-auto p-0">
                <label class="form-check-label" for="flexCheckDefault">
                  <img src="@/assets/images/Gift-Aid-Logo-Green.png" class="img-fluid checkbox_img">
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-3 green-text-dark font17 ">
          Yes, increase my donation of <b>{{currency}}{{total}}</b> to <b>{{currency}}{{giftaidTotal}}</b> at no extra cost
        </div>
        <div class="col-auto col-md-12 green-text-dark">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="true" id="money" v-model="ownMoneyValue">
            <label class="form-check-label" for="flexCheckDefault">
              Yes, this is my own money
            </label>
          </div>
        </div>
        <div class="col-12 my-3 font9 medium">
          I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years, to Al-Imdaad Foundation UK for the use of administration/operating costs. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to pay any difference.
        </div>
        <div class="col-11 py-3 mt-3 border-top" v-if="!user"></div>
        <div class="col-11 medium text-center" v-if="!user">
          Your tick for GiftAid allows us to maintain a 100% donations policy and ensures every penny of your donation benefits the needy recipients.
        </div>
      </div>
    </CardPlain>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue'))
  },
  name: 'Checkout GiftAid',
  props: ['ownMoney', 'giftAid', 'total', 'currency'],
  emits: ['update:ownMoney', 'update:giftAid'],
  computed: {
    ...mapGetters([
      'cart', 'user'
    ]),
    ownMoneyValue: {
      get () {
        return this.ownMoney
      },
      set (val) {
        this.$emit('update:ownMoney', val)
      }
    },
    giftAidValue: {
      get () {
        return this.giftAid
      },
      set (val) {
        this.$emit('update:giftAid', val)
      }
    },
    giftaidTotal () {
      if (this.total) {
        const ret = Number(this.total)
        return parseFloat(Math.round(ret * 1.25 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return 0
      }
    }
  }
}
</script>

<style scoped>
.checkbox_img {
  height: 39px;
}
.font9 {
  font-size: 9px;
}
</style>
